import React from "react";

export default function DiscoverNewJourneys() {
  return (
    <>
      <div className="discover-journey">
        <h3 data-aos="fade-right" data-aos-duration="1200">EXPERIENCE</h3>
        <p data-aos="fade-left" data-aos-duration="1200">
          His Holiness travels across the globe to liberate souls from the
          clutches of the material world.
        </p>
        <div className="horizontal-line"></div>
        <button class="button" data-aos="fade-right" data-aos-duration="1200">
          Future Events
          <span class="arrow">&rarr;</span>
        </button>
      </div>
    </>
  );
}
