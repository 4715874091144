// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AppHeader from './Components/subcompos/AppHeader';
import MainHomePage from './Pages/MainHomePage';
import AOS from 'aos';
import 'aos/dist/aos.css';


const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, 
      easing: 'ease-in-out', 
      once: false,       
      mirror: true,
    });
  }, []);
  return (
    <Router>
      <AppHeader/> 
      <br/>
      <Routes>
        <Route path="/" element={<MainHomePage />} />
      
      </Routes>
    </Router>
  );
};

export default App;
